<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >{{$t('generic-str.type')}}</label
                >
                <div class="col-md-12">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="word" name="type" class="custom-control-input" v-model="form.type" value="word">
                    <label class="custom-control-label" for="word">{{$t('key-word-component.label')}}</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="url" name="type" class="custom-control-input" v-model="form.type" value="url">
                    <label class="custom-control-label" for="url">URL</label>
                  </div>
                </div>
                <hr>
                <div class="col-md-12">
                  <input
                    v-model="form.info"
                    type="text"
                    class="form-control"
                    :class="{ 'invalid': isInvalid}"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-primary"
            >
              {{$t('generic-str.save')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SmsService from '@/services/sms.service';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Modal from '@/mixins/Modal';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {},
  data() {
    return {
      type: 'word',
      isInvalid: false,
      isSending: false,
    };
  },
  methods: {
    save() {
      this.isInvalid = false;
      if (this.form.info == null || this.form.info === '') {
        this.isInvalid = true;
        return;
      }

      this.isSending = true;
      switch (this.form.type) {
        case 'word':
          SmsService.editWords({
            id: this.form.id,
            word: this.form.info,
          }).then(
            (response) => {
              console.log(response);
              this.hide();
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Palavra chave atualizada com sucesso',
                type: 'success',
              });
              this.$emit('submit');
              this.isSending = false;
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
          break;
        case 'url':
          SmsService.editUrls({
            id: this.form.id,
            url: this.form.info,
          }).then(
            (response) => {
              console.log(response);
              this.hide();
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('key-word-component.success'),
                type: 'success',
              });
              this.$emit('submit');
              this.isSending = false;
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
          break;
        default:
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
